<template>
    <div class="main-wrapper">
        <div class="page-wrapper">
            <app-bread-crumb homeText="Beranda" :path="data.link" :homeRoute="data.name" :title="data.title"
                :description="data.description" />
            <div v-html="contents"></div>
            <component v-if="contents == ''" :is="currentComponent" :key="$route.params.id" />
        </div>
    </div>
</template>

<script>
import AppBreadCrumb from '@/views/layouts/bread-crumb.vue'
import datasample from './sample.json'

import { defineAsyncComponent, ref, shallowRef, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
    components: {
        AppBreadCrumb
    },
    data() {
        return {
            data: []
        }
    },
    setup() {
        const route = useRoute();
        const reroute = useRouter();

        const currentComponent = shallowRef(null);
        const param = ref(route.params.id); // Use route params in your component
        const contents = "";
        let data0 = datasample[route.params.id];

        if (data0 == undefined) {
            data0 = {
                name: "Badan Kebijakan Transportasi",
                title: "Badan Kebijakan Transportasi",
                description: "",
                link: [
                    {
                        title: "Badan Kebijakan Transportasi",
                        path: "/profiles/tugas-fungsi",
                        description: ""
                    }
                ]
            };
        }
        const data = ref(data0);

        const loadComponent = async (componentName) => {
            try {
                let datax = datasample;
                let isloaded = false;
                if (datax[componentName].contents == "") {
                    currentComponent.value = await defineAsyncComponent(() =>
                        import('@/views/modules/content/components/' + datax[componentName].component + '.vue')
                    );
                    isloaded = true;
                } else {
                    contents = datax[componentName].contents;
                }

                let data0 = datasample[route.params.id];

                if (data0 == undefined) {
                    data0 = {
                        name: "Badan Kebijakan Transportasi",
                        title: "Badan Kebijakan Transportasi",
                        description: "",
                        link: [
                            {
                                title: "Badan Kebijakan Transportasi",
                                path: "/profiles/tugas-fungsi",
                                description: ""
                            }
                        ]
                    };
                }
                data.value = data0;
            } catch (error) {
                currentComponent.value = await defineAsyncComponent(() =>
                    import('@/views/modules/content/components/blank.vue')
                );
            }
        };
        watch(() => route.params.id, (newId) => {
            param.value = newId;
            loadComponent(newId);
        });

        onMounted(() => {
            loadComponent(route.params.id);
        });

        return {
            currentComponent,
            data,
            contents,
            param
        };
    }
}
</script>

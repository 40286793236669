<template>
	<!-- Footer -->
	<footer class="footer pt-5">
		<div class="container">
			<div class="stay-tuned">
				<h4>Dapatkan info terbaru mengenai
					Baketrans</h4>
				<p>Berlangganan untuk mendapatkan informasi terbaru seputar baketrans</p>
				<b-form>
					<div class="form-group">
						<div class="group-img">
							<i class="feather-mail"></i>
							<b-form-input type="text" class="form-control" placeholder="Enter Email Address" />
						</div>
					</div>
					<b-button variant="primary" type="submit"> Subscribe</b-button>
				</b-form>
			</div>
		</div>

		<!-- Footer Top -->
		<div class="footer-top aos mb-4" data-aos="fade-up">
			<div class="container">
				<div class="row">
					<div class="col-lg-3 col-md-6">
						<!-- Footer Widget -->
						<div class="footer-widget">

						</div>
						<!-- /Footer Widget -->
					</div>
					<div class="col-lg-2 col-md-6">
						<!-- Footer Widget -->
						<div class="footer-widget footer-menu">
							<h2 class="footer-title">Layanan</h2>
							<ul>
								<li>
									<a href="https://ojs.balitbanghub.dephub.go.id/" target="_blank">E-Jurnal</a>
								</li>
								<li>
									<a href="https://elibrary.dephub.go.id/" target="_blank">E-Library</a>
								</li>
								<li>
									<a href="https://e-survey.kemenhub.go.id/" target="_blank">E-Survey</a>
								</li>
								<li>
									<a href="/" target="_blank">LAKIP</a>
								</li>
								<li>
									<a href="/" target="_blank">Laptah</a>
								</li>
							</ul>
						</div>
						<!-- /Footer Widget -->
					</div>
					<div class="col-lg-2 col-md-6">
						<!-- Footer Widget -->
						<div class="footer-widget footer-menu">
							<h2 class="footer-title">Direktori</h2>
							<ul>
								<li>
									<a href="/directory/laporan-tahunan">Laporan Tahunan</a>
								</li>
								<li>
									<a href="/directory/materi-kegiatan">Materi Kegiatan</a>
								</li>
								<li>
									<a href="/directory/peraturan">Regulasi / Peraturan</a>
								</li>
								<li>
									<a href="/directory/siaran-pers">Siaran Pers</a>
								</li>
								<li>
									<a href="/directory/haki" target="_blank">HAKI</a>
								</li>
							</ul>
						</div>
						<!-- /Footer Widget -->
					</div>
					<div class="col-lg-2 col-md-6">
						<!-- Footer Widget -->
						<div class="footer-widget footer-menu">
							<h2 class="footer-title">Lainnya</h2>
							<ul>
								<li>
									<a href="https://ppid.dephub.go.id/profil-unit-kerja/baketrans#show"
										target="_blank">PPID</a>
								</li>
								<li>
									<a href="https://sidata.baketrans.kemenhub.go.id/" target="_blank">Sidata</a>
								</li>
								<li>
									<a href="https://cloud.baketrans.kemenhub.go.id/index.php/s/Htt7YroTYSXnpoZ"
										target="_blank">Bulletin</a>
								</li>
							</ul>
						</div>
						<!-- /Footer Widget -->
					</div>
					<div class="col-lg-3 col-md-6">
						<!-- Footer Widget -->
						<div class="footer-widget">
							<h2 class="footer-title">Kontak</h2>
							<div class="footer-contact-info">
								<div class="footer-address">
									<i class="bi bi-telephone-fill"></i>
									<p><span>Hubungi Kami</span> <br>{{ (medsosData.phone ?? '') }} </p>
								</div>
								<div class="footer-address">
									<i class="bi bi-envelope-fill"></i>
									<p><span>Kirim Email</span> <br>{{ (medsosData.email ?? '') }} </p>
								</div>
								<div class="footer-widget">
									<div class="social-icon">
										<ul v-for="(item, index) in medsosData.medsos" :key="index">
											<li>
												<a :href="item.link" target="_blank" class="me-2">
													<i :class="item.icon"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- /Footer Top -->

		<!-- Footer Bottom -->
		<div class="footer-bottom">
			<div class="container">
				<!-- Copyright -->
				<div class="copyright">
					<div class="row">
						<div class="col-md-6">
							<div class="copyright-text">
								<p class="mb-0">Copyrights © {{ new Date().getFullYear() }} - Baketrans.
								</p>
							</div>
						</div>
						<div class="col-md-6">
							<!-- Copyright Menu -->
							<div class="copyright-menu">
								<ul class="policy-menu">
									<li>
										<router-link to="/">Beranda </router-link>
									</li>
									<li>
										<router-link to="/berita">Berita </router-link>
									</li>
								</ul>
							</div>
							<!-- /Copyright Menu -->
						</div>
					</div>
				</div>
				<!-- /Copyright -->
			</div>
		</div>
		<!-- /Footer Bottom -->
	</footer>
	<!-- /Footer -->
</template>

<script>

export default {
	props: ['medsosData'],
}
</script>



<style scoped>
.footer .footer-logo img {
	width: 300px !important;
	/* Add other styles if needed */
}

.footer .footer-bottom {
	font-size: 12px;
	padding: 5px;
}

.footer .footer-bottom .copyright {
	padding: 5px 0;
}

.footer .footer-bottom .copyright p {
	font-size: 12px !important;
}

.footer-address i {
	font-size: 28px;
	margin-right: 10px;
	color: #f9c11e;

}

.copyright-menu .policy-menu li a {
	font-size: 12px !important;
}
</style>
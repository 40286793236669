<template>
  <div class="row">
    <div class="col-lg-9">
      <div class="bloglistleft-widget blog-listview">
        <h3 class="p-0 m-0 text-muted font-weight-normal pb-3">
          <i class="bi bi-briefcase"></i> <span class="text-capitalize">{{
            (options.sCategory == undefined || options.sCategory == '' ? 'Directory' : options.sCategory) }}</span>
          <span class="float-end fs-11" v-if="totalRecord > 0">
            Ditemukan {{ totalRecord }} item
          </span>
          <span class="float-end fs-11" v-else="">
            Tidak ada data !
          </span>

        </h3>

        <template v-if="baseData.news">
          <div v-show="totalRecord < 1">
            <div class="container">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="banner-info">
                    <h2 class="p-0 m-0">Hasil Pencarian Tidak Ditemukan</h2>
                    <p class="p-0">Kata kunci <strong>'{{ options.sQuerySubmit }}'</strong>
                      tidak ditemukan dalam sistem kami, silahkan coba dengan kata kunci lain !</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-for="item in baseData.news.data" :key="item.id">
            <div class="blog-widget card-body">
              <div class="blog-img">
                <a @click.prevent="callshowImage(item.image)">
                  <img class="img-strecth " v-lazy="item.thumbnail" width="100%" height="400" alt="baketrans" />
                </a>
              </div>
              <div class="bloglist-content">
                <div class="">
                  <div class="review-details">
                    <h3 class="blog-title ellipsis3">
                      <router-link :to="`/directory/${options.slug}/${item.slug}`">
                        {{ item.title }}
                      </router-link>
                    </h3>

                    <div class="rating mb-0">
                      <div><i class="fa-sharp fa-solid fa-calendar-days"></i> {{ item.published }}</div>
                      <div><i class="feather-eye"></i> {{ item.views }}x dilihat</div>
                    </div>
                    <p class="mb-0 ellipsis4" v-html="item.description"></p>
                    <div class="reply-box ">
                      <p>
                        <span class="thumbsup fs-11">
                          <img class="icon32x32" src="/assets/images/logo.png" alt="author"> <strong
                            class="text-bkt-blue ps-2 fs-12 text-uppercase">
                            {{ item.author
                            }}</strong></span>
                      </p>
                      <p>
                      <div class="replylink p-1 m-0" :to="`/directory/${options.slug}/${item.slug}`">

                        <div class="action">
                          <a href="javascript:void(0)" class="action-btn btn-view"><i class="feather-eye"></i></a>
                          <a href="javascript:void(0)" class="action-btn btn-download"><i
                              class="feather-download"></i></a>

                        </div>
                      </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="col-lg-3 theiaStickySidebar">
      <div class="stickysidebar">
        <div class="card">
          <div class="card-body pb-1">
            <h6><i class="bi bi-search"></i> Cari Informasi</h6>
            <div class="input-group mb-3">
              <input type="text" class="form-control" v-model="options.sQuery"
                placeholder="tekan enter atau klik tombol..." maxlength="100" aria-label="search"
                aria-describedby="button-search" :disabled="options.isloading" @keypress="callcheckEnter">
              <button class="btn btn-outline-secondary" type="button" id="button-search" @click="callsearchData"
                :disabled="options.isloading">
                <i v-if="options.isloading" class="spinner-border spinner-border-sm align-middle"></i>
                <i v-else class="bi bi-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="rightsidebar blogsidebar">
          <div class="card">
            <h4><i class="bi bi-briefcase"></i> Direktori</h4>
            <ul class="blogcategories-list p-0">
              <li v-for="(row, index) in baseDataKategori" :key="item">
                <a href="javascript:;" @click.prevent="callchangeCategories(row, index)" v-if="!row.uioptions.loading">
                  <p class="category-title">{{ row.title }}</p>
                  <span>{{ row.total }} </span>
                </a>
                <a href="javascript:;" v-if="row.uioptions.loading">
                  <p class="category-title">{{ row.title }}</p>
                  <i class="spinner-border spinner-border-sm align-middle me-2"></i>
                  <span>{{ row.total }} </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="rightsidebar blogsidebar mt-4">
          <div class="card tags-widget">
            <h4><i class="feather-tag"></i> Tagar</h4>
            <ul class="tags">
              <li>#Kebijakan</li>
              <li>#Infrastruktur</li>
              <li>#Keselamatan</li>
              <li>#Transportasi</li>
              <li>#Darat</li>
              <li>#Laut</li>
              <li>#Udara</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: null
    },
    baseData: {
      type: Object,
      default: null
    },
    baseDataKategori: {
      type: Object,
      default: []
    },
    totalRecord: {
      type: Number,
      default: 0
    },
    sCategory: {
      type: String,
      default: ''
    },
    sQuerySubmit: {
      type: String,
      default: ''
    }
  },
  components: {

  },
  computed: {
  },
  methods: {
    callshowImage(url) {
      this.$emit('showImage', url);
    },
    callcheckEnter(event) {
      this.$emit('checkEnter', event);
    },
    async callsearchData() {
      await this.$emit('searchData');
    },
    async callchangeCategories(value, index) {
      await this.$emit('changeCategories', value, index);
    }
  },
  async mounted() {

    try {

    } finally {

    }
  },
}

</script>


<style scoped>
.pagination .pagination-center {
  width: auto;
}

.blog-img {
  width: 240px;
  /* Fixed width */
  height: 220px;
  /* Fixed height */
  overflow: hidden;
  border-radius: 10px 0 0 10px;
  border: 2px solid #F1F1F1;
}

.blog-img img {
  width: 100% !important;
  height: 100%;
  object-fit: fill;
  /* Ensure the image covers the entire area */
}

.blog-title {
  text-transform: uppercase;
}

.blog-listview .card .blog-widget {
  width: 108%;
}

@media (max-width: 767.98px) {
  .blog-listview .card .blog-widget {
    width: 100%;
  }
}

.ellipsis3 {
  -webkit-line-clamp: 3 !important;
  display: -webkit-box;
  line-height: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.ellipsis4 {
  -webkit-line-clamp: 4 !important;
  display: -webkit-box;
  line-height: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: justify;
}

.review-details .rating div {
  font-size: 12px;
  margin-left: 5px;
}

.rating i {
  font-size: 10px;
}

.icon32x32 {
  height: 24px;
  width: 24px;
  opacity: 0.7;
}

.img-strecth {
  width: 240px !important;
  height: 220px !important;
}


.rightsidebar .card ul.blogcategories-list li {
  list-style: none;
  margin-left: 0;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px;
}

.rightsidebar .card ul.blogcategories-list li span {
  float: right;
  background-color: #001165;
  color: #fff;
  border-radius: 3px;
  padding: 2px;
  min-width: 35px;
  max-width: 50px;
  height: 16px;
  text-align: center;

  line-height: 13px;
  font-size: 11px;
  border-left: 2px solid #F9C11E;
  border-right: 2px solid #F9C11E;
}

.rightsidebar .card ul.blogcategories-list li:before {
  content: "\f35a";
  font-family: FontAwesome;
  display: inline-block;
  margin-right: 10px;
  margin-left: 0;
  color: #001165;
  vertical-align: top;
}


.bloglistleft-widget.blog-listview .card p {
  font-size: 13px;
}

.blog-listview .bloglist-content {
  width: calc(100% - 310px);
}

.btn-view,
.btn-download {
  background: #ffffff;
  border: 1px solid #f9c11e;
  border-radius: 6px;
  padding: 4px 10px;
  color: #f9c11e;
  transition: 0.7s;
  margin: 0 0 0 8px !important;
}

.category-title {
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 !important;
  margin: 0 !important;
  display: inline-block;
}
</style>
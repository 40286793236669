import axiosApi from '@/lib/axios';
import { start, complete } from '../assets/plugins/nanobar/nanobar.js';
const nanoStart = start;
const nanocomplete = complete;

const kategoriPage = {
    namespaced: true,
    strict: false,
    state: {
        kategori: [],
        directory_kategori: [],
        galeri_foto: [],
        galeri_video: []
    },
    mutations: {
        SET_KATEGORI(state, payload) {
            state.kategori = payload
        },
        SET_DIRECTORY_KATEGORI(state, payload) {
            state.directory_kategori = payload
        }
        ,
        SET_GALERI_FOTO(state, payload) {
            state.galeri_foto = payload
        }
        ,
        SET_GALERI_VIDEO(state, payload) {
            state.galeri_video = payload
        }
    },
    actions: {
        getNewsCategory({ commit }) {
            //nanoStart();
            return new Promise((resolve, reject) => {

                axiosApi.get('kategori-page/get')
                    .then((response) => {
                        const tableData = response.data.data.map((item) => {
                            return {
                                ...item,
                                uioptions: { loading: false }
                            }
                        });
                        commit('SET_KATEGORI', tableData)
                        //commit('SET_KATEGORI', response.data.data)                        
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        // reject(error)
                    })
            })
        },
        getDirectoryCategory({ commit }) {
            // nanoStart();
            return new Promise((resolve, reject) => {

                axiosApi.get('kategori-page/get-directory')
                    .then((response) => {
                        const tableData = response.data.data.map((item) => {
                            return {
                                ...item,
                                uioptions: { loading: false }
                            }
                        });
                        commit('SET_DIRECTORY_KATEGORI', tableData)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        // reject(error)
                    })
            })
        },
        getFotoCategory({ commit }) {
            //nanoStart();
            return new Promise((resolve, reject) => {

                axiosApi.get('kategori-page/get-galeri-foto')
                    .then((response) => {
                        const tableData = response.data.data.map((item) => {
                            return {
                                ...item,
                                uioptions: { loading: false }
                            }
                        });
                        commit('SET_GALERI_FOTO', tableData)
                        //commit('SET_KATEGORI', response.data.data)                        
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        // reject(error)
                    })
            })
        },
        getVideoCategory({ commit }) {
            //nanoStart();
            return new Promise((resolve, reject) => {

                axiosApi.get('kategori-page/get-galeri-video')
                    .then((response) => {
                        const tableData = response.data.data.map((item) => {
                            return {
                                ...item,
                                uioptions: { loading: false }
                            }
                        });
                        commit('SET_GALERI_VIDEO', tableData)
                        //commit('SET_KATEGORI', response.data.data)                        
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        // reject(error)
                    })
            })
        },
    }
};

export default kategoriPage
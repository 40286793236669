<template>
    <!-- Category Section -->
    <section class="directory-section">
        <div class="container">
            <div class="section-heading heading-eight">
                <div class="row align-items-center">
                    <div class="col-md-12  text-center">
                        <h2>Direktori</h2>
                    </div>
                </div>
            </div>
            <div class="category-details">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6  d-flex">
                        <router-link to="/directory/materi-kegiatan" class="category-inner flex-fill">
                            <div class="category-img-two">
                                <img src="@/assets/media/images/icons/icon-materi.svg" class="dir-icon">
                            </div>
                            <div class="category-content">
                                <h4>Materi kegiatan</h4>
                                <!-- <p>43 Jobs Available</p> -->
                            </div>
                        </router-link>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6  d-flex">
                        <router-link to="/directory/kerjasama" class="category-inner flex-fill">
                            <div class="category-img-two">
                                <img src="@/assets/media/images/icons/icon-kerjasama.svg" class="dir-icon">
                            </div>
                            <div class="category-content">
                                <h4>Kerja sama</h4>
                                <!-- <p>43 Jobs Available</p> -->
                            </div>
                        </router-link>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6  d-flex">
                        <router-link to="#" class="category-inner flex-fill">
                            <div class="category-img-two">
                                <img src="@/assets/media/images/icons/icon-knowledge.svg" class="dir-icon">
                            </div>
                            <div class="category-content">
                                <h4>Knowledge Sharing Program</h4>
                                <!-- <p>43 Jobs Available</p> -->
                            </div>
                        </router-link>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6  d-flex">
                        <router-link to="/directory/haki" class="category-inner flex-fill">
                            <div class="category-img-two">
                                <img src="@/assets/media/images/icons/icon-haki.svg" class="dir-icon">
                            </div>
                            <div class="category-content">
                                <h4>Hak Atas Kekayaan Intelektual</h4>
                                <!-- <p>43 Jobs Available</p> -->
                            </div>
                        </router-link>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6  d-flex">
                        <router-link to="/directory/peraturan" class="category-inner flex-fill">
                            <div class="category-img-two">
                                <img src="@/assets/media/images/icons/icon-regulasi.svg" class="dir-icon">
                            </div>
                            <div class="category-content">
                                <h4>Regulasi</h4>
                                <!-- <p>43 Jobs Available</p> -->
                            </div>
                        </router-link>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6  d-flex">
                        <a href="https://jdih.kemenhub.go.id/" target="_blank" class="category-inner flex-fill">
                            <div class="category-img-two">
                                <img src="@/assets/media/images/icons/icon-jdih.svg" class="dir-icon">
                            </div>
                            <div class="category-content">
                                <h4>Jaringan Dokumentasi dan Informasi Hukum (JDIH)</h4>
                                <!-- <p>43 Jobs Available</p> -->
                            </div>
                        </a>
                    </div>
                </div>
                <div class="align-items-center justify-content-center d-flex">
                    <router-link to="/directory" class="category-btn">Lihat semua direktori <i
                            class="bi bi-arrow-right-circle"></i></router-link>
                </div>
            </div>
        </div>
    </section>
    <!-- /Category Section -->
</template>

<script>
export default {
    name: 'IndexHome'
}
</script>

<style scoped>
.dir-icon {
    height: 38px;
}

.directory-section {
    background: url(@/assets/media/images/bg/bg1.jpg) no-repeat;
    background-size: cover;
    padding: 30px;
}
</style>
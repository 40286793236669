<template>
  <ul class="main-nav">
    <li :class="IndexMenu ? 'active' : 'notactive'">
      <router-link to="/"><i class="home-icon hide-on-mobile"></i> Beranda</router-link>
    </li>
    <li class="has-submenu">
      <a href="javascript:void(0);">
        <i class="profile-icon hide-on-mobile"></i> Profil <i class="fas fa-chevron-down"></i>
      </a>
      <ul class="submenu">
        <li>
          <router-link :to="{ name: 'profiles', params: { id: 'sejarah' } }">Sejarah</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'profiles', params: { id: 'tugas-fungsi' } }">Tugas dan Fungsi</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'profiles', params: { id: 'informasi-pejabat' } }">Informasi Pejabat</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'profiles', params: { id: 'struktur-organisasi' } }">Struktur
            Organisasi</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'profiles', params: { id: 'filosofi-logo' } }">Logo</router-link>
        </li>
      </ul>
    </li>

    <li class="has-submenu">
      <a href="javascript:void(0);">
        <i class="public-icon hide-on-mobile"></i> Publikasi <i class="fas fa-chevron-down"></i>
      </a>
      <ul class="submenu">
        <li>
          <router-link :to="`/berita`">Berita</router-link>
        </li>
        <li>
          <router-link :to="`/directory/siaran-pers`">Siaran Pers</router-link>
        </li>
        <li>
          <router-link :to="`/berita/kategori/pojok-kebijakan`">Pojok Kebijakan</router-link>
        </li>
        <li>
          <router-link :to="`/berita/kategori/opini-insan-transportasi`">Opini Insan</router-link>
        </li>
      </ul>
    </li>

    <li class="has-submenu">
      <a href="javascript:void(0);">
        <i class="galeri-icon hide-on-mobile"></i> Galeri <i class="fas fa-chevron-down"></i>
      </a>
      <ul class="submenu">
        <li>
          <router-link :to="{ name: 'pubs-galeri-foto' }">Galeri Foto</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'pubs-galeri-video' }">Galeri Video</router-link>
        </li>
      </ul>
    </li>
    <li class="has-submenu">
      <a href="javascript:void(0);">
        <i class="layanan-icon hide-on-mobile"></i> Layanan <i class="fas fa-chevron-down"></i>
      </a>
      <ul class="submenu">
        <li>
          <router-link :to="{ name: 'pubs-layanan' }">Layanan Kami</router-link>
        </li>
        <li><router-link to="survey-kepuasan-pelanggan">Survey Kepuasan
            Pelanggan</router-link></li>
      </ul>
    </li>
    <li class="has-submenu">
      <a href="javascript:void(0);">
        <i class="ppid-icon hide-on-mobile"></i> PPID <i class="fas fa-chevron-down"></i>
      </a>
      <ul class="submenu">
        <li>
          <a href="https://ppid.dephub.go.id/profil-unit-kerja/baketrans#show" target="_blank">PPID</a>
        </li>
      </ul>
    </li>
    <li><router-link :to="{ name: 'pubs-contact' }"><i class="kontak-icon hide-on-mobile"></i> Kontak</router-link></li>
  </ul>
</template>

<script>
import router from '@/router'


export default {
  components: {},
  mounted() {
    if ($(window).width() <= 991) {
      var Sidemenu = function () {
        this.$menuItem = $(".main-nav a");
      };

      function init() {
        var $this = Sidemenu;
        $(".main-nav a").on("click", function (e) {
          if ($(this).parent().hasClass("has-submenu")) {
            e.preventDefault();
          }
          if (!$(this).hasClass("submenu")) {
            $("ul", $(this).parents("ul:first")).slideUp(350);
            $("a", $(this).parents("ul:first")).removeClass("submenu");
            $(this).next("ul").slideDown(350);
            $(this).addClass("submenu");
          } else if ($(this).hasClass("submenu")) {
            $(this).removeClass("submenu");
            $(this).next("ul").slideUp(350);
          }
        });
      }
      init();
    }
  },
  computed: {

  },
};
</script>

<style scoped>
.home-icon {
  content: url('@/assets/media/images/icons/menu-beranda.svg');
  width: 20px;
  vertical-align: middle;
}

.profile-icon {
  content: url('@/assets/media/images/icons/menu-profil.svg');
  width: 20px;
  vertical-align: middle;
}


.public-icon {
  content: url('@/assets/media/images/icons/menu-publikasi.svg');
  width: 20px;
  vertical-align: middle;
}

.galeri-icon {
  content: url('@/assets/media/images/icons/menu-galeri.svg');
  width: 20px;
  vertical-align: middle;
}

.layanan-icon {
  content: url('@/assets/media/images/icons/menu-service.svg');
  width: 20px;
  vertical-align: middle;
}

.ppid-icon {
  content: url('@/assets/media/images/icons/menu-ppid.svg');
  width: 20px;
  vertical-align: middle;
}

.kontak-icon {
  content: url('@/assets/media/images/icons/menu-kontak.svg');
  width: 20px;
  vertical-align: middle;
}

/* CSS to hide the icon on mobile view */
@media (max-width: 991px) {
  .hide-on-mobile {
    display: none;
  }
}
</style>
<template>
    <section class="blog-section-four">
        <div class="container">
            <div class="row">
                <div class="col-md-9">
                    <div class="section-heading heading-four">
                        <h2 class="p-0 m-0">Berita terbaru</h2>
                        <p>Lihat berita terbaru seputar baketrans</p>
                    </div>
                </div>
                <div class="col-md-3 text-md-end">
                    <div class="theme-btn">
                        <router-link to="/berita">
                            Lihat Selengkapnya
                            <span>
                                <i class="feather-arrow-right"></i>
                            </span>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="row">
                <template v-if="beritaData">
                    <div class="col-lg-3 col-md-6 d-flex" v-for="item in beritaData" :key="item.id">
                        <div class="blog grid-blog blog-four flex-fill">
                            <div class="blog-image p-2">
                                <router-link :to="{ name: 'pubs-berita-detail', params: { id: item.slug } }">
                                    <img class="img-fluid" :src="item.thumbnail" alt="baketrans">
                                </router-link>
                            </div>
                            <div class="blog-content">
                                <h3 class="blog-title">
                                    <router-link :to="{ name: 'pubs-berita-detail', params: { id: item.slug } }">
                                        {{ item.title }}
                                    </router-link>
                                </h3>
                                <p class="truncate">{{ item.description }}</p> <!-- Truncated description -->
                                <ul class="entry-meta meta-item">
                                    <li>
                                        <div class="post-author">
                                            <div class="post-author-img">
                                                <img :src="require(`@/assets/media/images/logo/logo-tiny.png`)"
                                                    alt="Post Author">
                                            </div>
                                            <div>
                                                <a href="javascript:void(0);"> <span>Badan Kebijakan
                                                        Transportasi</span></a>
                                                <p>{{ item.published }}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: ['beritaData'],
    data() {

        return {

        };
    },

};
</script>

<style scoped>
.truncate {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.section-heading {
    h2 {
        font-size: 28px;
    }
}

.interset-btn .btn {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
}

.blog-image img {
    min-height: 180px;

}

@media (max-width: 991.98px) {
    .grid-blog .post-author {
        width: auto;
    }
}
</style>
<template>
    <div class="main-wrapper">
        <div class="page-wrapper">
            <app-bread-crumb class="bg-white" homeText="Beranda" :path="data.link" :homeRoute="data.name"
                :title="data.title" :description="data.description" />
            <div class="direktori-layanan">
                <div class="container">
                    <div class="card-grid">
                        <div class="card" v-for="(directory, index) in baseDataKategori" :key="index">
                            <h3 class="card-title">{{ directory.title }}</h3>
                            <img v-lazy="directory.icon" class="card-image" />
                            <p class="card-description">{{ directory.description }}</p>
                            <a :href="`directory/` + directory.slug" rel="noopener noreferrer" class="card-button"><i
                                    class="bi bi-link-45deg"></i> Selengkapnya</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppBreadCrumb from '@/views/layouts/bread-crumb.vue'
import { mapState, mapActions } from 'vuex'

export default {
    components: {
        AppBreadCrumb
    },
    data() {
        return {
            activeTab: 'layanan-internal',
            isloading: false,
            placeholderImage: '@/assets/img/icons/image-load-failed.png',
            data: {
                name: "Direktori",
                title: "Direktori Informasi",
                description: "",
                link: [
                    {
                        title: "Direktori",
                        path: "/directory",
                        description: ""
                    }
                ]
            }
        };
    },
    watch: {
        async $route(to, from) {
            this.isloading = true
            try {
                await this.getDirectoryCategory();
            } finally {
                this.isloading = false;
            }
        }
    },
    computed: {
        ...mapState('kategoriPage', {
            baseDataKategori: (state) => state.directory_kategori
        }),
    },
    async mounted() {
        this.isloading = true
        try {
            await this.getDirectoryCategory();
        } finally {
            this.isloading = false;
        }
    },
    methods: {
        ...mapActions('kategoriPage', ['getDirectoryCategory'])
    }
};
</script>

<style scoped>
.direktori-layanan {
    padding-top: 50px;
    margin-top: 5px;
    margin-bottom: 100px;
    background-color: #F3F4F6;
    /* background-image: url('@/assets/media/images/bg/whitebg.png'); */

    .nav-tabs {
        list-style: none;
        padding-top: 30px;
        padding-bottom: 20px;
        display: flex;
        gap: 25px;
        justify-content: center;
        border: 0;

        li {
            cursor: pointer;
            font-size: 16px;
            padding: 10px 100px;
            position: relative;
            background-color: #fff;
            border-radius: 100px;
            color: #111827;
            font-weight: 500;
            transition: all 0.3s ease;

            &.active {
                background-color: #001165;
                color: #ffff;
            }

            &:hover {
                color: #f9c11e;
            }
        }
    }

    .tab-content {
        padding: 10px;
    }

    .card-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;

    }

    .card {

        /* Fixed width */
        min-height: 400px;
        max-height: 460px;
        /* Fixed height */
        padding: 20px;
        border-radius: 1.25rem;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
        background-color: #fff;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        /* Make the card a positioning context */
        overflow: hidden;
        /* To ensure content doesn't overflow */
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        /* Add transition for hover effect */
    }

    .card:hover {
        transform: translateY(-10px);
        /* Lift the card up */
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        /* Enhance shadow on hover */
    }

    .card-title {
        font-size: 1.125rem;
        line-height: 1.75rem;
        margin: 0 0 20px 0;
        text-transform: uppercase;
        /* Increased bottom margin to add more space */
    }

    .card-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        position: absolute;
        /* Position the image absolutely */
        top: 75px;
        /* Adjust the top position as needed */
        left: 50%;
        transform: translateX(-50%);
        /* Center the image horizontally */
    }

    .card-description {
        font-size: .75rem;
        color: #6b7280;
        margin: 0 0 10px 0;
        /* Margin to separate from the button */
        text-align: center;
        overflow: hidden;
        /* Ensure text doesn't overflow */
        text-overflow: ellipsis;
        /* Add ellipsis for overflowed text */
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-height: 1.25rem;
        -webkit-box-orient: vertical;
    }

    .card-button {
        padding: 10px 20px;
        border: none;
        border-radius: 25px;
        background-color: #001165;
        color: #ffff;
        font-size: 13px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        text-decoration: none;
        /* Ensure the link looks like a button */
        display: inline-block;
        /* Ensure proper button styling */
    }

    .card-button:hover {
        background-color: #f9c11e;
        color: #001165;
    }

    @media (max-width: 1200px) {
        .card-grid {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (max-width: 768px) {
        .card-grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 480px) {
        .card-grid {
            grid-template-columns: 1fr;
        }
    }
}
</style>
import axiosApi from '@/lib/axios';
import { start, complete } from '../assets/plugins/nanobar/nanobar.js';
const nanoStart = start;
const nanocomplete = complete;

const layananPage = {
    namespaced: true,
    strict: false,
    state: {
        layanan: [],
    },
    mutations: {
        SET_LAYANAN(state, payload) {
            state.layanan = payload
        },

    },
    actions: {
        getLayanan({ commit }, params) {
            //nanoStart();
            return new Promise((resolve, reject) => {

                axiosApi.get('link-page/layanan')
                    .then((response) => {
                        commit('SET_LAYANAN', response.data.data)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        reject(error)
                    })
            })
        },

    }
};

export default layananPage
<template>
    <div class="main-wrapper home-nine">
        <app-beranda-carousel v-if="baseData.banner" :bannerData="baseData.banner" />
        <app-beranda-berita v-if="baseData.berita" :beritaData="baseData.berita" />
        <app-beranda-direktori />
        <app-beranda-kalender />
        <app-beranda-pojok v-if="baseData.pojok" :pojokData="baseData.pojok" />
        <app-beranda-galeri v-if="baseData.galeri" :galeriData="baseData.galeri" />
        <app-beranda-medsos v-if="baseData.medsos" :medsosData="baseData.medsos" />
    </div>
</template>

<script>
import AppBerandaCarousel from './section/beranda-carousel.vue';
import AppBerandaBerita from './section/beranda-berita.vue';
import AppBerandaDirektori from './section/beranda-direktori.vue';
import AppBerandaKalender from './section/beranda-kalender.vue';
import AppBerandaPojok from './section/beranda-pojok-bijak.vue';
import AppBerandaMedsos from './section/beranda-medsos.vue';
import AppBerandaGaleri from './section/beranda-galeri.vue';

import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            isloading: true
        }
    },
    components: {
        AppBerandaCarousel,
        AppBerandaBerita,
        AppBerandaDirektori,
        AppBerandaKalender,
        AppBerandaPojok,
        AppBerandaMedsos,
        AppBerandaGaleri
    },
    computed: {
        ...mapState('homePage', {
            baseData: (state) => state.home
        })
    },
    methods: {
        ...mapActions('homePage', ['getHomePages'])
    },
    async mounted() {
        this.isloading = true;
        try {
            await this.getHomePages();
        } finally {
            this.isloading = false;
        }
    },
}

</script>
<template>
  <!-- Header -->
  <header class="header header-nine">
    <div class="header-top">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <ul class="d-flex justify-content-start car-top-left">
              <div v-for="(item, index) in medsosData.medsos" :key="index">
                <li class="d-flex align-items-center">
                  <a :href="item.link" target="_blank" class="me-2">
                    <i :class="item.icon"></i>
                  </a>
                </li>
              </div>

              <li class="d-flex align-items-center">|</li>
              <li class="d-flex align-items-center header-link">
                <i class="feather-phone me-2"></i> {{ (medsosData.phone ?? '') }}
              </li>
              <li class="d-flex align-items-center">|</li>
              <li class="d-flex align-items-center text-black">
                <i class="feather-mail me-2 "></i> {{ (medsosData.email ?? '') }}
              </li>
            </ul>
          </div>
          <div class="col-md-4 d-flex justify-content-end align-items-center">
            <button class="search-button" @click="$refs.searchPopup.toggleSearchPopup">
              <i class="bi bi-search search-icon"></i>
            </button>
            <SearchPopup ref="searchPopup" />
            <div class="language-dropdown" ref="dropdown">
              <button class="dropdown-toggle" @click="toggleDropdown">
                <span class="flag-icon" :style="{ backgroundImage: 'url(' + currentFlag + ')' }"></span>
                <!-- No text displayed here -->
                <i class="feather-chevron-down"></i>
              </button>
              <div class="dropdown-menu" v-if="isDropdownOpen">
                <div class="dropdown-item" @click="selectLanguage('EN')">
                  <span class="flag-icon" :style="{ backgroundImage: 'url(' + flags.gb + ')' }"></span>
                  <div class="language-info">
                    <div class="country-name">United Kingdom</div>
                    <div class="language-name">English</div>
                  </div>
                </div>
                <div class="dropdown-item" @click="selectLanguage('ID')">
                  <span class="flag-icon" :style="{ backgroundImage: 'url(' + flags.id + ')' }"></span>
                  <div class="language-info">
                    <div class="country-name">Indonesia</div>
                    <div class="language-name">Bahasa Indonesia</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:;">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img src="@/assets/img/BrandWeb.png" class="img-fluid" alt="Logo">
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/BrandWeb.png" class="img-fluid" alt="Logo">
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
          </div>
          <app-nav-menu />
        </div>
      </nav>
    </div>
    <app-newsticker />
    <div class="separator-nav">

    </div>
  </header>
</template>

<script>
import AppNavMenu from './navmenu.vue';
import SearchPopup from './searchpopup.vue';
import AppNewsticker from './newsticker.vue';

export default {
  components: {
    SearchPopup,
    AppNavMenu,
    AppNewsticker
  },
  props: ['medsosData'],
  data() {
    return {
      isSearchPopupOpen: false,
      isDropdownOpen: false,
      currentLanguage: 'Indonesian',
      currentFlag: require('@/assets/img/flags/id.svg'),
      flags: {
        gb: require('@/assets/img/flags/gb.svg'),
        id: require('@/assets/img/flags/id.svg')
      },
      currentIndex: 0,
      autoplayInterval: null,
    };
  },
  methods: {
    toggleSearchPopup() {
      this.isSearchPopupOpen = !this.isSearchPopupOpen;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectLanguage(lang) {
      if (lang === 'en') {
        this.currentLanguage = 'English';
        this.currentFlag = this.flags.gb;
      } else if (lang === 'id') {
        this.currentLanguage = 'Indonesian';
        this.currentFlag = this.flags.id;
      }
      localStorage.setItem("x-lang", lang)
      this.isDropdownOpen = false;
      window.location.reload();
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.newsItems.length - 1;
      }
    },
    nextSlide() {
      if (this.currentIndex < this.newsItems.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },
    autoplay() {
      this.autoplayInterval = setInterval(() => {
        this.nextSlide();
      }, 3000); // Change slide every 3 seconds
    },
    onSearchClick() {
      // Handle search icon click
      console.log('Search icon clicked');
    }
  },
  mounted() {
    const lang = localStorage.getItem("x-lang");
    if (lang == 'en') {
      this.currentFlag = this.flags.gb;
    } else {
      this.currentFlag = this.flags.id;
    }

    document.addEventListener('click', this.handleClickOutside);
    $('body').append('<div class="sidebar-overlay"></div>');
    $(document).on('click', '#mobile_btn', function () {
      $('main-wrapper').toggleClass('slide-nav');
      $('.sidebar-overlay').toggleClass('opened');
      $('html').addClass('menu-opened');
      return false;
    });

    $(document).on('click', '.sidebar-overlay', function () {
      $('html').removeClass('menu-opened');
      $(this).removeClass('opened');
      $('main-wrapper').removeClass('slide-nav');
    });

    $(document).on('click', '#menu_close', function () {
      $('html').removeClass('menu-opened');
      $('.sidebar-overlay').removeClass('opened');
      $('main-wrapper').removeClass('slide-nav');
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>

<style scoped>
.language-dropdown {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.dropdown-toggle {
  background: transparent;
  border: none;
  color: #000;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.flag-icon {
  width: 20px;
  height: 20px;
  background-size: cover;
  margin-right: 4px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 250px;
  /* Adjust width as needed */
  padding: 8px 0;
  /* Padding inside the card */
  z-index: 1000;
}

.dropdown-item {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  /* background: #f5f5f5; */
}

.language-info {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.country-name {
  font-weight: bold;
}

.language-name {
  font-size: 14px;
  color: #555;
}





.search-icon {
  margin-right: 20px;
  /* Adjust this value as needed */
  color: #001165;

  font-size: 15px;
  /* font-size: 1.5rem; Adjust font size if needed */
}

.search-button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
}

.separator-nav {
  height: 2px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
}

.twiter-color {
  color: #1DA1F2;
}

.facebook-color {
  color: #1877F2;
}

.instagram-color {
  color: #C13584;
}

.youtube-color {
  color: #FF0000;
}

.header-link {
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  vertical-align: baseline;
  color: rgb(163 8 8);
}
</style>
<template>
  <div class="bloglistleft-widget blog-listview">

    <div class="row">
      <div class="col-lg-8">
        <h3 class="p-0 m-0 text-muted font-weight-normal pb-3">
          <i class="bi bi-briefcase"></i> <span class="text-capitalize">{{
            (options.sCategory == undefined || options.sCategory == '' ? 'Directory' : options.sCategory) }}</span>
        </h3>
      </div>
      <div class="col-lg-4">
        <div class="input-group">
          <input type="text" class="form-control search-input" v-model="options.sQuery"
            :placeholder="`Cari informasi` + (totalRecord > 0 ? ' (ditemukan ' + totalRecord + ' item)' : '')"
            maxlength="100" aria-label="search" aria-describedby="button-search" :disabled="options.isloading"
            @keypress="callcheckEnter">

          <button class="btn btn-outline-secondary" type="button" id="button-search" @click="callsearchData"
            :disabled="options.isloading">
            <i v-if="options.isloading" class="spinner-border spinner-border-sm align-middle"></i>
            <i v-else class="bi bi-search"></i>
          </button>
        </div>
      </div>
    </div>
    <template v-if="baseData.news">
      <div v-show="totalRecord < 1">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="banner-info">
                <h2 class="p-0 m-0">Hasil Pencarian Tidak Ditemukan</h2>
                <p class="p-0">Kata kunci <strong>'{{ sQuerySubmit }}'</strong>
                  tidak ditemukan dalam sistem kami, silahkan coba dengan kata kunci lain !</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 d-lg-flex" v-for="item in baseData.news.data" :key="item.id">
          <div class="blog grid-blog">

            <div class="blog-content">
              <div class="d-flex related-post-meta">
                <div class="me-2">
                  <img src="/assets/images/logo.png" alt="Baketrans" class="logo-img rounded-circle icon32x32">
                </div>
                <div class="media-body text-start">
                  <div class="">
                    <div class="user-name fs-10"><strong>Badan Kebijakan Transportasi</strong></div>
                    <div class="user-last-chat fs-10">{{ item.published }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="blog-image p-2">
              <a @click.prevent="callshowImage(item.image)">
                <img class="img-strecth " v-lazy="item.thumbnail" width="100%" height="400" alt="baketrans" />
              </a>
            </div>
            <div class="blog-content">
              <h3 class="blog-title">
                <div class="amount-details">
                  <div class="amount">
                    <span class="validrate ellipsis4">
                      <router-link :to="`/directory/${options.slug}/${item.slug}`">
                        {{ item.title }}
                      </router-link>
                    </span>
                  </div>
                </div>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: null
    },
    baseData: {
      type: Object,
      default: null
    },
    totalRecord: {
      type: Number,
      default: 0
    },
    sCategory: {
      type: String,
      default: ''
    },
    sQuerySubmit: {
      type: String,
      default: ''
    }
  },
  components: {

  },
  computed: {
  },
  methods: {
    callshowImage(url) {
      this.$emit('showImage', url);
    },
    callcheckEnter(event) {
      this.$emit('checkEnter', event);
    },
    async callsearchData() {
      await this.$emit('searchData');
    },
    async callchangeCategories(value, index) {
      await this.$emit('changeCategories', value, index);
    }
  },
  async mounted() {
    try {

    } finally {

    }
  },
}

</script>

<style scoped>
.search-input {

  background-color: #ffffffff;
  border: 1px solid rgb(247, 247, 247);
  border-radius: calc(0.5*48px);
  color: #000000ff;
  font-family: inherit;
  font-size: inherit;
  height: 100%;
  outline: 0;
  padding-inline-end: calc(20px);
  padding-inline-start: calc(20px);
  position: relative;
  width: 100%;
  margin-bottom: 30px;

}

.blog-image {
  width: 100%;
  height: auto;
}

.blog-image:hover img {
  transform: none;
}

.blog-image img {
  border: 1px solid #eeeff0;
  border-radius: 10px;
}

.grid-blog .blog-title a {
  font-size: 14px;
  font-weight: 500;
  line-height: 1 !important;
}

.grid-blog .entry-meta li {
  margin: 0 0 15px;
  font-size: 10px;
  color: #9c9c9c;
}

.blog-listview .card .blog-widget {
  width: 108%;
}

@media (max-width: 767.98px) {
  .blog-listview .card .blog-widget {
    width: 100%;
  }
}

.ellipsis4 {
  -webkit-line-clamp: 4 !important;
  display: -webkit-box;
  line-height: 20px !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.icon32x32 {
  height: 32px;
  width: 32px;
}

.input-group .btn {
  position: static;
  z-index: 2;
  height: 46px;
  border: 1px solid #f5f5f5;
  right: 40px;
  border-radius: 52px;
}
</style>
import { createStore } from "vuex";


import homePage from './homePage.js'
import beritaPage from './beritaPage.js'
import kategoriPage from './kategoriPage.js'
import directoryPage from './directoryPage.js'
import layananPage from './layananPage.js'
import galeriPage from './galeriPage.js'

const store = createStore({
  modules: {
    homePage,
    beritaPage,
    kategoriPage,
    directoryPage,
    layananPage,
    galeriPage
  }
})

export default store
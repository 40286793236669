<template>
    <div class="main-wrapper">
        <div class="page-wrapper">
            <app-bread-crumb homeText="Beranda" :path="data.link" :homeRoute="data.name" :title="data.title"
                :description="data.description" />
            <div class="contactus-info">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 contactus-img col-md-12">
                            <div class="contactleft-info">
                                <img src="/assets/images/banner/20241008.jpg" class="img-fluid" alt="">
                                <div class="contactinfo-content">
                                    <div class="contact-hours">
                                        <h6>Kontak</h6>
                                        <ul>
                                            <li>Jl. Medan Merdeka Tim. No.5, RT.2/RW.1</li>
                                            <li>Gambir, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10110</li>

                                            <li> Email : baketrans@kemenhub.go.id</li>
                                        </ul>
                                    </div>
                                    <div class="contact-hours">
                                        <h6>Hubungi Kami</h6>
                                        <ul>
                                            <li><i class="bi bi-telephone-fill"></i> (021) 3483-3060</li>
                                            <li><i class="bi bi-telegram"></i> Layanan Pengaduan Web</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 contactright-map col-md-12">
                            <div class="google-maps">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.6709043387377!2d106.82805807488653!3d-6.174794660507659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f432ff9642bd%3A0xca3ffac92439a2f5!2sBadan%20Penelitian%20dan%20Pengembangan%20Perhubungan!5e0!3m2!1sen!2sid!4v1728344144295!5m2!1sen!2sid"
                                    width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--/contact Information-->
        </div>
    </div>

</template>

<script>
import AppBreadCrumb from '@/views/layouts/bread-crumb.vue'

export default {
    components: {
        AppBreadCrumb
    },
    data() {
        return {
            data: {
                name: "/",
                title: "Kontak Kami",
                description: "",
                link: [
                    {
                        title: "Kontak Kami",
                        path: "/kontak",
                        description: ""
                    },
                    {
                        title: "Kontak Kami",
                        path: "/kontak",
                        description: ""
                    }
                ]
            },
        }
    },
}
</script>

<style scoped>
.contactinfo-content {
    position: absolute;
    top: 55%;
    background: #001165;
    padding: 37px;
    right: -80px;
    border-radius: 10px;
    text-align: center;
    color: #ffffff;
    z-index: 1;
    transform: translateY(-50%);
}
</style>
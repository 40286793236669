<template>
    <section class="car-location">
        <div class="container">
            <div class="col-lg-9 mx-auto">
                <div class="section-heading section-heading-nine aos" data-aos="fade-up">
                    <div class="row align-items-center">
                        <div class="col-md-12 aos aos-init aos-animate text-center" data-aos="fade-up">
                            <h2 class="p-0 m-0">Media Sosial Baketrans</h2>
                            <p class="p-0 m-0">Semua tentang Baketrans ada disini, yuk follow medsos kami !</p>
                        </div>
                    </div>
                </div>

                <div class="grid-container">
                    <div class="large-item">
                        <a href="https://www.instagram.com/baketrans" target="_blank">
                            <div class="car-location-img">
                                <img src="/assets/images/banner/995869602.jpg" class="img-fluid" alt="instagram" />
                                <span><i class="bi bi-instagram instagram-color"></i> Instagram</span>
                            </div>
                        </a>
                    </div>
                    <div class="small-items">
                        <div class="small-item">
                            <a href="https://twitter.com/Baketrans" target="_blank">
                                <div class="car-location-img">
                                    <img src="/assets/images/banner/1775509028.jpg" class="img-fluid" alt="twitter" />
                                    <span><i class="bi bi-twitter twiter-color"></i> Twitter</span>
                                </div>
                            </a>
                        </div>
                        <div class="small-item">
                            <a href="https://www.facebook.com/balitbanghub.perhubungan.9" target="_blank">
                                <div class="car-location-img">
                                    <img src="/assets/images/banner/1413029359.jpg" class="img-fluid" alt="facebook" />
                                    <span><i class="bi bi-facebook facebook-color"></i> Facebook</span>
                                </div>
                            </a>
                        </div>
                        <div class="small-item">
                            <a href="https://www.youtube.com/channel/UCWJN_3rlnFcdZLn4pNW9EUw" target="_blank">
                                <div class="car-location-img">
                                    <img src="/assets/images/banner/ms-youtube.jpg" class="img-fluid" alt="yotube" />
                                    <span><i class="bi bi-youtube youtube-color"></i> Youtube</span>
                                </div>
                            </a>
                        </div>
                        <div class="small-item">
                            <a href="https://www.tiktok.com/@baketrans" target="_blank">
                                <div class="car-location-img">
                                    <img src="/assets/images/banner/788627849.jpg" class="img-fluid" alt="tiktok" />
                                    <span><i class="bi bi-tiktok tiktok-color"></i> TikTok</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions, useStore } from 'vuex'

export default {
    props: ['medsosData'],
    data() {
        return {
            data: []
        };
    },

};
</script>

<style scoped>
.section-heading h2,
.section-heading-nine h2 {
    font-size: 28px;
}

.car-location .large-item .car-location-img img,
.car-location .small-item .car-location-img img {
    opacity: 1;
    padding: 10px;
    background-color: #f1f1f1;

}

.car-location .large-item .car-location-img img:hover,
.car-location .small-item .car-location-img img:hover {
    opacity: 0.5;

}

.car-location .large-item .car-location-img::before,
.car-location .small-item .car-location-img::before,
.car-location .large-item .car-location-img img,
.car-location .small-item .car-location-img img {
    border-radius: 1.5rem;
}

.car-location .car-location-img {
    text-align: center;
}

.car-location .car-location-img span {
    position: absolute;
    top: 50px;
    left: 70px;

    padding-left: 15px;
    padding-right: 15px;
    font-weight: 400;
    font-size: 0.9rem;
    color: #ffffff;
    transition: 0.5s;

}
</style>
<template>
  <section class="beranda-pojok-section ">
    <div class="container">
      <h2 class="section-title">Pojok Kebijakan</h2>
      <p class="section-subtitle">Baca Publikasi Kebijakan Kami</p>

      <Carousel v-bind="settings" :breakpoints="breakpoints">
        <Slide v-for="item in pojokData" :key="item.id">
          <router-link to="/directory/pojok-kebijakan" class="custom-carousel__item">
            <div class="custom-carousel__content">
              <div class="custom-carousel__image card p-2">
                <img :src="item.thumbnail" alt="Baketrans" class="custom-card-img" />
              </div>
              <div v-if="!hideDesc" class="custom-carousel__desc">
                <div class="desc-card">
                  <div class="desc-header">
                    <h4 class="desc-title">{{ item.title }}</h4>
                    <span class="desc-date">
                      <i class="fa fa-calendar"></i> {{ item.published }}
                    </span>
                  </div>
                  <p class="desc-content">{{ item.description }}</p>
                  <div class="desc-footer">
                    <span class="desc-views">
                      <i class="fa fa-eye"></i> {{ item.views }} dilihat
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  props: ['pojokData'],
  components: {
    Carousel,
    Navigation,
    Slide,
  },
  data() {
    return {
      hideDesc: false,
      settings: {
        itemsToShow: 1,
        itemsToScroll: 1,
        wrapAround: true,
        arrows: true,
        autoplay: 6000,
        transition: 500,
      },
      breakpoints: {
        0: {
          itemsToShow: 1,
          itemsToScroll: 1,
          snapAlign: 'center',
        },
        991: {
          itemsToShow: 1,
          itemsToScroll: 1,
          snapAlign: 'start',
        },
      },
      updateHideDesc: () => {
        this.hideDesc = window.innerWidth <= 991;
      }
    }
  },
  mounted() {
    this.updateHideDesc();
    window.addEventListener('resize', this.updateHideDesc());
  },
  methods: {
    // updateHideDesc: () => {
    //   this.hideDesc = window.innerWidth <= 991;
    // }
  }
});
</script>

<style scoped>
.beranda-pojok-section {
  background-color: #f9fafc;
  /* Set background color */
  padding: 50px 0;
  background: url(@/assets/media/images/bg/bg2.jpg) no-repeat;
  background-size: cover;
}

.section-title {
  font-weight: bold;
  /* Make the text bold */
  text-align: center;
  /* Center align the text */
  font-size: 28px;
  /* Set font size to 36px */
}

.section-subtitle {
  text-align: center;
  /* Center align the text */
}

.custom-carousel__item {
  display: flex;
  justify-content: center;
  /* Center content horizontally */
  align-items: center;
  /* Center content vertically */
  padding: 10px;
  width: 75%;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
}

.custom-carousel__item:hover {
  color: inherit;
  /* Ensure text color does not change on hover */
}

.custom-carousel__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  /* Ensure no overflow beyond container */
}

.custom-carousel__image {
  flex: 0 0 auto;
  width: 300px;
  /* Fixed width */
  height: 520px;
  /* Fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* Hide overflow if image is too big */
  margin-right: 20px;
  /* Add spacing between image card and desc card */
  box-shadow: 5px 5px 10px rgb(73 73 73 / 44%) !important;
}

.custom-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure the image covers the container */
  border-radius: 10px;
  border: 1px solid #f3f3f4;
}

.custom-carousel__desc {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: left;
  /* Align text to the left */
}

.desc-card {
  background: #ffffff;
  border-radius: 15px;
  padding: 25px;
  /* Increased padding for inner space */
  box-shadow: rgba(19, 16, 34, 0.05) 0px 4.4px 20px -1px, rgba(19, 16, 34, 0.03) 0px 4px 9px -1px;
  height: auto;
  width: 100%;
  min-height: 300px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  border-top: 4px solid #001165;
  border-bottom: 4px solid #f9c11e;
}

.desc-header {
  margin-bottom: 5px;
  /* Add space below the header */
}

.desc-title {
  font-weight: bold;
  font-size: 1.6em;
  /* Adjust as needed */
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  /* Show up to 2 lines of text */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.desc-date {
  font-size: 0.9em;
  position: absolute;
  /* Position the date absolutely within the card */
  bottom: 25px;
  /* Space from the bottom */
  left: 25px;
  /* Space from the left */
}

.desc-content {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 13px;
}

.desc-footer {
  display: flex;
  justify-content: flex-end;
  /* Align footer items to the right */
  align-items: center;
  position: absolute;
  /* Position the footer absolutely within the card */
  bottom: 25px;
  /* Space from the bottom */
  right: 25px;
  /* Space from the right */
}

.desc-views {
  font-size: 0.9em;
}

.fa-calendar,
.fa-eye {
  margin-right: 5px;
}

.vue3-carousel__navigation button {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

/* Media query to adjust layout on smaller screens */
@media (max-width: 991px) {
  .custom-carousel__content {
    flex-direction: column;
    /* Stack image and description vertically */
    align-items: center;
    /* Center items horizontally */
  }

  .custom-carousel__image {
    width: 100%;
    /* Make the image take full width on small screens */
    height: auto;
    /* Adjust height accordingly */
    margin-right: 0;
    /* Remove right margin on small screens */
    margin-bottom: 20px;
    /* Add spacing below the image card */
  }

  .custom-carousel__desc {
    display: flex;
    /* Ensure description is displayed */
    flex-direction: column;
    /* Stack description elements vertically */
  }
}
</style>
<template>
    <!-- Destination Banner -->
    <section class="section-blk destination-blk">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 ">
                    <div class="title-blk">
                        <div class="title-left">
                            <h2 class="p-0 m-0">Galeri Foto &amp; Video</h2>
                            <h5>Dapatkan foto dan video terbaru dari kami !</h5>
                        </div>
                        <div class="theme-btn">
                            <router-link to="/galeri/foto" class="search-btn">
                                Lihat Selengkapnya
                                <span>
                                    <i class="feather-arrow-right"></i>
                                </span>
                            </router-link>
                        </div>
                    </div>
                    <div class="destination-carousel d-flex justify-content-center">
                        <div class="destination-slider owl-carousel owl-theme gallery-content">
                            <Carousel :wrap-around="false" :settings="settings" :breakpoints="breakpoints">
                                <Slide v-for="(item, index) in galeriData" :key="item.id" class="col-lg-12">
                                    <div class="destination-widget gallery-widget">
                                        <div class="fav-item">
                                            <span class="featured-text">
                                                {{ (item.type == '1' ? 'Video' : 'Foto') }}
                                            </span>
                                        </div>
                                        <a @click.prevent="showImg(index, item.type)" class="desti-link "
                                            data-fancybox="galeri">
                                            <h2 v-if="item.type == '1' && item.ytid != ''"
                                                class="bi-youtube youtube-color youtube-icon">
                                            </h2>
                                            <img :src="item.thumbnail">
                                        </a>

                                        <div class="overlay-blk">
                                            <p>{{ item.title }}</p>
                                            <div class="theme-btn">
                                                <router-link v-if="item.type == '1'" to="/galeri/video"
                                                    class="search-btn">
                                                    Selengkapnya
                                                    <span>
                                                        <i class="feather-arrow-right"></i>
                                                    </span>
                                                </router-link>
                                                <router-link v-if="item.type == '0'" to="/galeri/foto"
                                                    class="search-btn">
                                                    Selengkapnya
                                                    <span>
                                                        <i class="feather-arrow-right"></i>
                                                    </span>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                                <template #addons>
                                    <Navigation />
                                </template>
                            </Carousel>
                        </div>
                        <vue-easy-lightbox :visible="visible" :imgs="img" :index="index" :z-index="2000"
                            @hide="handleHide">
                        </vue-easy-lightbox>
                    </div>
                </div>
            </div>
        </div>

    </section>
    <!-- /Destination Banner -->


</template>

<script>

import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import Swal from 'sweetalert2'

export default {
    props: ['galeriData'],
    data() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: "center"
            },
            breakpoints: {
                500: {
                    itemsToShow: 2,
                    snapAlign: "center"
                },

                767: {
                    itemsToShow: 2,
                    snapAlign: "center"
                },
                // 991 and up
                991: {
                    itemsToShow: 3,
                    snapAlign: "start"
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: "start"
                },
                1424: {
                    itemsToShow: 4,
                    snapAlign: "start"
                }
            },
            visible: false,
            index: 0,
            img: []
        };
    },
    created() {
        if (this.galeriData) {
            for (let i = 0; i < this.galeriData.length; i++) {
                if (this.galeriData[i].ytid == "1") {
                    if (this.galeriData[i].ytid !== null) {
                        this.img.push('https://youtu.be/' + this.galeriData[i].ytid);
                    }
                } else {
                    this.img.push(this.galeriData[i].image);
                }
            }
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    methods: {
        showImg(index, ntype) {
            this.index = index;
            if (ntype == '0') {
                this.visible = true;
            } else {
                this.visible = false;
                let vid = "";
                if (this.galeriData[index].ytid != "") {
                    vid = `<iframe class="ytvideo"
                        src="https://www.youtube.com/embed/`+ this.galeriData[index].ytid + `?autoplay=1&mute=1">
                        </iframe>`;
                } else {
                    if (this.galeriData[index].video_url != "") {
                        vid = `  <video class="ytvideo" controls autoplay>
                                    <source src="`+ this.galeriData[index].video + `" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>`;
                    }
                }
                Swal.fire({
                    html: vid,
                    showConfirmButton: false,
                    showCloseButton: true,
                    //timer: 2500,
                    width: 854,
                    heightAuto: false,

                    customClass: {
                        confirmButton: "btn btn-light-success btn-sm",
                        cancelButton: "btn btn-light-danger btn-sm"
                    },
                    buttonsStyling: false
                });
            }

        },
        handleHide() {
            this.visible = false;
        }
    }
};
</script>

<style>
.vel-modal {
    z-index: 9999 !important;
}

.destination-widget {
    height: 94%;
    background-image: url(@/assets/media/images/bg/frame.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    /* padding: 15px; */
    border-radius: 30px;
    border: 10px solid #f1f1f1;

}


.destination-carousel .overlay-blk h3 {
    font-size: 0.9rem;
}

.destination-carousel .destination-widget a.desti-link img {
    width: 100% !important;
    height: 100% !important;
    top: 110px !important;
    position: sticky !important;
    /* border: 3px solid #007DC2; */
    border-radius: 0 !important;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.featured-text {
    position: fixed;
    top: 68px;
    right: 25px;
    z-index: 2;
    background: #000 !important;
    text-align: right;

}

.destination-carousel .overlay-blk {
    padding: 8px;
    transform: none !important;
    opacity: 1 !important;

}

.destination-carousel .overlay-blk p {

    transform: none !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.destination-carousel .destination-widget {
    margin: 5px;
}

.destination-carousel .overlay-blk .theme-btn {
    height: 50px;
    padding: 4px 4px 4px 15px;
}

.destination-carousel .overlay-blk .theme-btn a:hover {
    position: relative;
    z-index: 1;
    background: #223089;
    border-radius: 100px;
    color: #ffffff;
    font-weight: 700;
    padding-left: 15px;
}

.section-blk.destination-blk .destination-carousel .destination-slider .carousel .carousel__next {
    border-radius: 0 !important;
    background: transparent !important;
    width: 0 !important;
    height: 0 !important;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid black;
    top: 50% !important;
    opacity: 0.2;
    /* Change color as needed */
}

.section-blk.destination-blk .destination-carousel .destination-slider .carousel .carousel__prev {
    border-radius: 0 !important;
    background: transparent !important;
    width: 0 !important;
    height: 0 !important;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid black;
    top: 50% !important;
    opacity: 0.2;
}

.swal2-container {
    z-index: 10000 !important;
}

.swal2-popup {
    padding: 0.5rem 0 1.25em !important;
    border: 4px solid #F9C11E !important;
    border-radius: 20px !important;
    background: #000a3d !important;
    color: #fff !important;
    font-family: inherit !important;
    font-size: 0.7rem !important;
}

.swal2-close {
    width: 1em !important;
    height: 0.6em !important;
    margin-bottom: -1em !important;
}

.ytvideo {
    width: 100%;
    height: 480px;
}

.youtube-icon {
    left: 100px;
    color: #ff0000;
    font-size: 5em;
    display: inline;
    top: 40%;
    position: sticky;
    z-index: 1;
    text-align: center;
}

@media (min-width: 1px) {
    .section-blk.destination-blk .destination-carousel .destination-slider .carousel .carousel__prev {
        left: -10px !important;
        top: 50% !important;
    }

    .section-blk.destination-blk .destination-carousel .destination-slider .carousel .carousel__next {
        right: -10px !important;
        top: 50% !important;
    }
}

@media (max-width: 767.98px) {
    .destination-blk .destination-carousel .carousel .carousel__viewport ol {
        left: inherit !important;
    }

    .ytvideo {
        width: 100%;
        height: 200px;
    }
}
</style>
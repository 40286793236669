import axios from 'axios';

const axiosApi = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000000,
});

// Request interceptor
axiosApi.interceptors.request.use((config) => {
    const lang = localStorage.getItem('x-lang')
    if(!lang){
        localStorage.setItem('x-lang','id')
        lang = 'id'
    }

    config.headers = {'X-LANG': lang,...config.headers};
    return config
})

// Response interceptor
axiosApi.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error)
    },
);
  

export default axiosApi;
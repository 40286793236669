<template>
    <div class="newsticker-container">
        <div class="news-heading">
            <img :src="require(`@/assets/media/images/icons/icon-speaker.svg`)" class="me-1 hide-on-mobile"> Berita
        </div>
        <div class="newsticker">

            <div class="news">
                <template v-if="newsTicker.length > 0">
                    <a v-for="(newsItem, index) in newsTicker" :key="index"
                        :class="['news-single', 'truncate', { active: index === currentActive }]">
                        <span class="badge badge-warning">{{ newsItem.author }}</span> {{ newsItem.title }}
                    </a>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'NewsTicker',
    data() {
        return {
            currentActive: 0,
            duration: 4000,
            intervalId: null,
        };
    },
    async mounted() {
        try {
            await this.getNewsTicker()
        } finally {
            this.startTicker();
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    computed: {
        ...mapState('homePage', {
            newsTicker: (state) => state.newsTicker
        }),
    },
    methods: {
        ...mapActions('homePage', ['getNewsTicker']),
        startTicker() {
            this.intervalId = setInterval(this.changeNews, this.duration);
        },
        changeNews() {
            if (this.newsTicker.length === 0) return;

            if (this.currentActive >= this.newsTicker.length - 1) {
                this.currentActive = 0;
            } else {
                this.currentActive += 1;
            }
        },
    },
};
</script>
<style scoped>
.newsticker-container {
    display: flex;
    justify-content: center;
    width: 100%;
    background: #007dc2;
}

.newsticker {
    color: #73c2fb;
    padding: 10px 32px;
    display: flex;
    overflow: hidden;
    width: 100%;
    margin-left: 100px;
    /* Optional: limit the max width */
}

.newsticker::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(transparent, #4682B4);
    /* Adjusted gradient color */
}

/* .newsticker .news-heading {
    font-size: 13px;
    padding-right: 20px;
    margin-right: 10px;
    border-right: 1px solid #fff;
    color: #fff;
    min-width: 90px;
} */

.newsticker .news {
    position: relative;
    width: 100%;
    height: 1.5em;
    /* Set a fixed height to prevent collapse */
    overflow: hidden;
    text-shadow: 1px 1px 2px #1B4089, 1px 1px 10px #d41919;
    letter-spacing: 1.5px;
}

.newsticker .news-single {
    font-size: 1em;
    color: #fff;
    text-decoration: none;
    font-weight: normal;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-8px);
    transition: all 400ms ease;
    pointer-events: none;
}

.newsticker .news-single.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
    /* Adjust as needed */
    display: block;
}

.badge-warning {
    background-color: #001461;
    color: #ffffff;
    padding: 0.4em 0.8em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 9px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    margin-right: 0.8em;
    border-radius: 50px;
    text-transform: uppercase;
    min-width: 80px;
}

.newsticker-container .news-heading {
    --f: .5em;
    /* control the folded part*/
    --r: .8em;
    /* control the ribbon shape */
    width: 120px;
    height: 50px;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-inline: 1em;
    letter-spacing: 1px;
    line-height: 2.9em;
    background: #00146194;
    border-top: var(--f) solid #0005;
    border-right: var(--r) solid #0000;
    clip-path:
        polygon(calc(100% - var(--r)) 100%, 0 100%, 0 var(--f), calc(100% - var(--r)) var(--f),
            100% calc(50% + var(--f)/2));
}

.newsticker-container .news-heading img {
    width: 25px;
    /* -webkit-filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.80));
    -webkit-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear; */
}

/* @media (min-width: 1px) {
    .newsticker {
        padding: 10px 132px;
    }
}

@media (min-width: 1320px) {
    .newsticker {
        padding: 10px 32px;
    }
} */
</style>

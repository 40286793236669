<template>
  <div class="loader" v-show="isloading"></div>
  <div v-show="!isloading">
    <app-header :medsosData="infoData" />
    <router-view />
    <app-foot :medsosData="infoData" />
    <whatsapp-fab />
    <app-scroll />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import AppHeader from '@/views/layouts/header.vue';
import AppFoot from '@/views/layouts/footer.vue'
import AppScroll from '@/views/layouts/scroll.vue'
import whatsappFab from '@/views/layouts/whatsapp-fab.vue';
export default {
  name: 'App',
  components: {
    AppHeader,
    AppFoot,
    AppScroll,
    whatsappFab
  },
  data() {
    return {
      isloading: true
    }
  },
  computed: {
    ...mapState('homePage', {
      infoData: (state) => state.info
    })
  },
  methods: {
    ...mapActions('homePage', ['getGlobalInfo'])
  },
  async mounted() {
    this.isloading = true;
    try {
      await this.getGlobalInfo()
    } finally {
      this.isloading = false;
    }
  },
}
</script>
<style>
/* Basic loader styles */
.loader {
  border: 10px solid #001165;
  /* Light gray */
  border-radius: 100%;
  border-top: 10px solid #F9C11E;
  border-bottom: 10px solid #F9C11E;
  /* Blue */
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  position: fixed;
  left: calc(54% - 116px);
  top: calc(50% - 116px);
  background-image: url('@/assets/media/images/logo/logo-tiny.png');
  background-size: cover;
}

@media (max-width: 767.98px) {
  .loader {
    left: calc(65% - 116px);
    top: calc(40% - 116px);
  }
}

/* Spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
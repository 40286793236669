<template>
    <section class="kalender-section">
        <div class="container ">
            <h2 class="section-title" data-aos="fade-up">Kalender Baketrans
                <p>Kalender ini berisi acara-acara yang akan diadakan oleh Baketrans</p>
            </h2>

            <div class="calendar-container row">
                <div class="col-md-6 calendar-side" data-aos="fade-up">
                    <div class="calendar-list-card">
                        <v-date-picker  @dayclick="onChangeDate" v-model="dateRange" is-range expanded :attributes="calendarAttributes" class="custom-event-calendar" :hide-header="false" :theme-styles="customTheme" />
                        <div class="event-list">
                            <ul>
                                <li v-for="event in filterData" :key="event.date" class="event-item"
                                    @click="selectEvent(event)">
                                    <img :src="getImagePath(event.imageKey)" alt="Event image" />
                                    <div class="event-details">
                                        <h4 class="event-title">{{ event.label }}</h4>
                                        <p class="event-description">{{ event.content }}</p>
                                    </div>
                                    <div class="right-section">
                                        <span class="event-date">{{ formatDate(event.date, event.endDate) }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 event-details-side" data-aos="fade-up">
                    <div v-if="selectedEvent" class="event-details-view">
                        <img :src="getImagePath(selectedEvent.imageKey)" alt="Event image" />
                        <div class="event-details-text">
                            <h2 class="event-details-title">{{ selectedEvent.label }}</h2>
                            <p class="event-details-description">{{ selectedEvent.content }}</p>
                            <span class="event-details-date">{{ formatDate(selectedEvent.date, selectedEvent.endDate)
                                }}</span>
                        </div>
                    </div>
                    <div v-else class="no-event-selected">
                        <p>Select an event to see details</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { DatePicker } from 'ant-design-vue';
import calendarData from './beranda-kalender.json';
import moment from'moment';

export default {
    data() {
        return {
            events: [],
            calendarAttributes: [],
            selectedEvent: null,
            placeholderImage: 'https://via.placeholder.com/60',
            customTheme: {
                backgroundColor: '#fff',
                borderColor: '#ddd',
                borderRadius: '8px',
                padding: '1rem',
                hoverBackgroundColor: '#eee',
                selectedColor: '#f9c11e'
            },
            dateRange: null
        };
    },
    computed:{
        filterData(){
            if(this.dateRange){
                return this.events.filter(event => moment(event.date).format('YYYY-MM-DD') >= moment(this.dateRange.start).format('YYYY-MM-DD') &&  moment(event.date).format('YYYY-MM-DD') <=  moment(this.dateRange.end).format('YYYY-MM-DD'));
            }else{
                return this.events;
            }
        }
    },
    mounted() {
        this.events = calendarData.sort((a, b) => new Date(a.date) - new Date(b.date));

        if (this.events.length > 0) {
            this.selectedEvent = this.events[0];
        }

        this.calendarAttributes = this.events.map(event => (
            {
                key: event.label,
                highlight: {
                    color: '#001165',
                    fillMode: 'solid',
                },
                dates: [new Date(event.date)]
            },
            {
                highlight: {
                    style: {
                        backgroundColor: '#f9c11e',
                        borderColor: '#001165',
                    },
                },
                dates: [new Date(event.date)]
            }
        ));
    },
    methods: {
        formatDate(startDate, endDate) {
            const options = { month: 'short', day: 'numeric' };
            const start = new Intl.DateTimeFormat('en-US', options).format(new Date(startDate));
            if (endDate) {
                const end = new Intl.DateTimeFormat('en-US', options).format(new Date(endDate));
                return start + '-' + end
            }
            return start;
        },
        selectEvent(event) {
            this.selectedEvent = event;
        },
        getImagePath(imageKey) {
            return imageKey ? require(`@/assets/img/bkt/${imageKey}`) : this.placeholderImage;
        }
    },
    onChangeDate(){

    }
};
</script>


<style scoped>
.kalender-section {
    padding: 20px;
    background-color: #ffffff;
    margin-bottom: 85px;
    margin-top: 10px;
}

.section-title {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 50px;
    text-align: center;
}

.section-title p {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.calendar-container {
    display: flex;
    justify-content: space-between;
}

.calendar-side {
    display: flex;
    flex-direction: column;
}

.calendar-list-card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.custom-event-calendar {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: none;
    /* Remove shadow from calendar itself */
}

.event-list {
    margin-top: 20px;
    max-height: 250px;
    overflow-y: auto;
    background-color: #ffffff;

    box-shadow: none;
    /* Remove shadow from event list */
    padding: 10px;
    flex-grow: 1;
    border: 1px solid #cbd5e1
}

.event-list::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.event-list::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
}

.event-list::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}

.event-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Align items to fill the space horizontally */
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.event-item img {
    width: 100px;
    height: 75px;
    border-radius: 4px;
    margin-right: 15px;
    object-fit: cover;
    flex-shrink: 0;
    /* Prevent the image from shrinking */
}

.event-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* Title and description stacked */
    overflow: hidden;
    /* Ensure text does not overflow */
}

.event-title {
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.event-description {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
    /* Small space between title and description */
    line-height: 1.4;
    overflow: hidden;
    /* Ensure text does not overflow */
    text-overflow: ellipsis;
    /* Add ellipsis for overflowing text */
    white-space: nowrap;
    /* Prevent text from wrapping */
}

.event-date {
    font-size: 12px;
    color: #888;
    margin-left: 10px;
    /* Separate the date from the text */
    white-space: nowrap;
    /* Prevents text wrapping for the date */
}

.event-item .right-section {
    display: flex;
    align-items: center;
    /* Aligns the date in the center vertically */
}

.event-item:hover {
    background-color: #f8f8f8;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-color: #ccc;
}

.event-details-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 20px;
    flex-grow: 1;

}



.event-details-view {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
}

.event-details-view img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
}

.event-details-text {
    flex-grow: 1;
}

.event-details-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.event-details-description {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
    line-height: 1.6;
}

.event-details-date {
    font-size: 14px;
    color: #888;
    margin-top: 10px;
}

.no-event-selected {
    text-align: center;
    color: #888;
    padding: 20px;
    flex-grow: 1;
}

.no-event-selected p {
    font-size: 18px;
}
</style>
import axiosApi from '@/lib/axios';
import { start, complete } from '.././assets/plugins/nanobar/nanobar.js';
const nanoStart = start;
const nanocomplete = complete;

const beritaPage = {
    namespaced: true,
    strict: false,
    state: {
        berita: [],
        berita_content: [],
    },
    mutations: {
        SET_BERITA(state, payload) {
            state.berita = payload
        },
        SET_BERITA_CONTENT(state, payload) {
            state.berita_content = payload
        }
    },
    actions: {
        getNews({ commit }, params) {
            //nanoStart();
            return new Promise((resolve, reject) => {
                let pagex = Number(params[0]);
                if (isNaN(pagex) && !isFinite(pagex)) {
                    pagex = 1;
                }
                if (params[2] == undefined || params[2] == null || params[2] == '') {
                    params[2] = 'utama';
                }
                axiosApi.get('berita-page/get?page=' + pagex + '&q=' + params[1] + '&c=' + params[2])
                    .then((response) => {
                        commit('SET_BERITA', response.data.data)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        reject(error)
                    })
            })
        },
        getNewsContent({ commit }, params) {
            //nanoStart();
            return new Promise((resolve, reject) => {
                let pagex = Number(params[0]);
                if (isNaN(pagex) && !isFinite(pagex)) {
                    pagex = 1;
                }
                axiosApi.get('berita-page/get-content?slug=' + params[0])
                    .then((response) => {
                        commit('SET_BERITA_CONTENT', response.data.data)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        reject(error)
                    })
            })
        }
    }
};

export default beritaPage
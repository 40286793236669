import axiosApi from '@/lib/axios';
import { start, complete } from '../assets/plugins/nanobar/nanobar.js';
const nanoStart = start;
const nanocomplete = complete;

const directoryPage = {
    namespaced: true,
    strict: false,
    state: {
        materi_kegiatan: [],
        materi_content: [],
    },
    mutations: {
        SET_MATERI_KEGIATAN(state, payload) {
            state.materi_kegiatan = payload
        },
        SET_MATERI_CONTENT(state, payload) {
            state.materi_content = payload
        },

    },
    actions: {
        getMateriKegiatan({ commit }, params) {
            //nanoStart();
            return new Promise((resolve, reject) => {
                let pagex = Number(params[0]);
                if (isNaN(pagex) && !isFinite(pagex)) {
                    pagex = 1;
                }
                if (params[2] == undefined || params[2] == null || params[2] == '') {
                    params[2] = '';
                }
                axiosApi.get('directory-page/get-materi-kegiatan?page=' + pagex + '&q=' + params[1] + '&c=' + params[2])
                    .then((response) => {
                        commit('SET_MATERI_KEGIATAN', response.data.data)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        reject(error)
                    })
            })
        },
        getMateriContent({ commit }, params) {
            //nanoStart();
            return new Promise((resolve, reject) => {

                axiosApi.get('directory-page/get-materi-content?id=' + params[0] + '&meta=' + params[1])
                    .then((response) => {
                        commit('SET_MATERI_CONTENT', response.data.data)
                        resolve(response.data)
                        //nanocomplete();
                    })
                    .catch((error) => {
                        //nanocomplete();
                        reject(error)
                    })
            })
        },

    }
};

export default directoryPage
<template>
  <div class="main-wrapper">
    <div class="page-wrapper">
      <app-bread-crumb homeText="Beranda" :path="data.link" :homeRoute="data.name"
        :title="(options.sCategory == undefined || options.sCategory == '' ? '' : options.sCategory)"
        :description="data.description" />


      <div class="bloglisting">
        <div class="container">
          <div v-if="options.sViewMode == 'list'">
            <viewList v-if="options.sViewMode == 'list'" :baseData="baseData" :baseDataKategori="baseDataKategori"
              :totalRecord="totalRecord" @showImage="showImage" @checkEnter="checkEnter" @searchData="searchData"
              @changeCategories="changeCategories" :options="options" />

          </div>

          <template v-if="options.sViewMode == 'grid'">
            <viewGrid v-if="options.sViewMode == 'grid'" :baseData="baseData" :baseDataKategori="baseDataKategori"
              :totalRecord="totalRecord" @showImage="showImage" @checkEnter="checkEnter" @searchData="searchData"
              @changeCategories="changeCategories" :options="options" />

          </template>

          <!-- Pagination -->

          <div :class="(options.sViewMode == 'grid' ? 'blog-pagination col-lg-12' : 'blog-pagination col-lg-9')"
            v-if="totalRecord > 0">
            <nav>
              <ul class="pagination justify-content-center pt-4">
                <li class="page-item previtem" :class="{ disabled: options.currentPage === 1 }">
                  <a class="page-link" href="#" @click.prevent="prevPage">
                    <i class="fas fa-regular fa-arrow-left"></i>
                  </a>
                </li>
                <li class="justify-content-center pagination-center">
                  <div class="pagelink">
                    <ul>
                      <li class="page-item" v-for="page in visiblePages" :key="page"
                        :class="{ active: options.currentPage === page, 'page-ellipsis': page === '...' }">
                        <a class="page-link" href="#" @click.prevent="goToPage(page)" v-if="page !== '...'">{{ page
                          }}</a>
                        <span v-else class="page-link">{{ page }}</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="page-item nextlink" :class="{ disabled: options.currentPage === totalPages }">
                  <a class="page-link" href="#" @click.prevent="nextPage">
                    <i class="fas fa-regular fa-arrow-right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <!-- /Pagination -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadCrumb from '@/views/layouts/bread-crumb.vue'
import viewList from './directory-mode-list.vue'
import viewGrid from './directory-mode-grid.vue'
import Swal from 'sweetalert2'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    AppBreadCrumb,
    viewList,
    viewGrid
  },
  data() {
    return {
      options: {
        isloading: false,
        sQuery: '',
        sQuerySubmit: '',
        sCategory: '',
        sViewMode: '',
        placeholderImage: '@/assets/img/icons/image-load-failed.png',
        currentPage: 1,
        slug: '',
      },
      data: {
        name: "/",
        title: "Direktori",
        description: "",
        link: [
          {
            title: "Direktori",
            path: "/directory",
            description: ""
          },
          {
            title: "Informasi",
            path: "",
            description: ""
          }
        ]
      }
    };
  },
  watch: {
    async $route(to, from) {
      this.isloading = true
      try {
        const id = (to.params.id == undefined ? '' : to.params.id);
        if (id == undefined || id == null || id == '') {
          await this.getMateriKegiatan([1, "", ""])
        } else {
          await this.getMateriKegiatan([1, this.options.sQuerySubmit, id]);
        }
        this.options.sViewMode = this.baseData.categories.mode;
        this.options.sCategory = this.baseData.categories.title;
        this.data.link[1].title = this.baseData.categories.title;
        this.options.slug = this.baseData.categories.slug;
        await this.getDirectoryCategory(["berita"])
      } finally {

        this.isloading = false;
      }
    }
  },
  computed: {
    ...mapState('directoryPage', {
      baseData: (state) => state.materi_kegiatan
    }),
    ...mapState('kategoriPage', {
      baseDataKategori: (state) => state.directory_kategori
    }),
    // Total pages based on filtered results
    totalPages() {
      return this.baseData.news.last_page;
    },
    itemsPerPage() {
      return this.baseData.news.per_page;
    },
    totalRecord() {
      let total = 0;
      if (this.baseData.news) {
        total = this.baseData.news.total;
      }
      return total;
    },


    // Determine visible pages with ellipses
    visiblePages() {
      const pages = [];
      const maxPagesToShow = 8;//this.itemsPerPage;
      const currentPage = this.baseData.news.current_page;
      const totalPages = this.totalPages;

      if (totalPages <= maxPagesToShow) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        let startPage = Math.max(currentPage - 1, 1);
        let endPage = Math.min(currentPage + 1, totalPages);

        if (currentPage <= 2) {
          endPage = maxPagesToShow;
        } else if (currentPage >= totalPages - 1) {
          startPage = totalPages - (maxPagesToShow - 1);
        }

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }

        if (startPage > 1) {
          pages.unshift('...');
          pages.unshift(1);
        }

        if (endPage < totalPages) {
          pages.push('...');
          pages.push(totalPages);
        }
      }

      return pages;
    }
  },
  async mounted() {
    this.isloading = true
    try {

      if (this.$route.params.id == undefined || this.$route.params.id == null || this.$route.params.id == '') {
        await this.getMateriKegiatan([1, "", ""])
      } else {
        await this.getMateriKegiatan([1, "", this.$route.params.id])
      }
      this.options.sViewMode = this.baseData.categories.mode;
      this.options.sCategory = this.baseData.categories.title;
      this.options.slug = this.baseData.categories.slug;
      this.data.link[1].title = this.baseData.categories.title;
      await this.getDirectoryCategory([""])
    } finally {
      this.isloading = false;
    }
  },
  methods: {
    ...mapActions('directoryPage', ['getMateriKegiatan']),
    ...mapActions('kategoriPage', ['getDirectoryCategory']),
    checkEnter(event) {
      if (event.key === 'Enter') { // Check if the key is Enter
        this.searchData();
      }
    },
    async searchData() {
      try {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.options.sCategory = (this.options.sCategory == undefined ? '' : this.options.sCategory);
        this.options.sQuerySubmit = this.options.sQuery;
        await this.getMateriKegiatan([1, this.options.sQuerySubmit,this.$route.params.id]);
      } finally {
        this.options.currentPage = 1;

      }
    },
    async changeCategories(value, index) {
      this.$router.push('/directory/' + value.slug);
    },
    showImage(url) {
      if (url == null) {
        url = this.options.placeholderImage;
      }
      this.$startLoading();
      Swal.fire({
        html: `
        <div v-lazy-container="{ selector: 'img', error: '/assets/images/icons/image-load-failed.png', loading: '/assets/images/icons/image-load.gif' }">
          <img class="img-fluid" src="${url}">
        </div>
        `,
        showConfirmButton: false,
        showCloseButton: true,
        //timer: 2500,
        width: 854,
        heightAuto: false,

        customClass: {
          confirmButton: "btn btn-light-success btn-sm",
          cancelButton: "btn btn-light-danger btn-sm"
        },
        buttonsStyling: false,
        didOpen: () => {
          this.$completeLoading();
        }
      });
    },
    async goToPage(page) {
      if (page !== '...' && page > 0 && page <= this.totalPages) {
        try {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          await this.getMateriKegiatan([page, this.options.sQuerySubmit, this.$route.params.id])
        } finally {
          this.options.currentPage = page;
        }
      }
    },
    nextPage() {
      if (this.options.currentPage < this.totalPages) {
        this.options.currentPage++;
      }
    },
    prevPage() {
      if (this.options.currentPage > 1) {
        this.options.currentPage--;
      }
    },
    formattedDate(tdate) {
      if (tdate) {
        const changedDate = tdate.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
        var date = new Date(changedDate);

        return new Intl.DateTimeFormat('id-ID', {
          timeZone: 'Asia/Jakarta',
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          weekday: 'long'

        }).format(date);
      }
    },
  }
};
</script>

<style scoped>
.pagination .pagination-center {
  width: auto;
}
</style>
<template>
  <div class="main-wrapper">
    <div class="page-wrapper">
      <app-bread-crumb homeText="Beranda" :path="data.link" :homeRoute="data.name" :title="data.title"
        :description="data.description" />
      <section class="berita-single-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div class="card">
                <div class="card-body">
                  <!-- Meta Information -->
                  <div class="blog-meta align-items-center justify-content-between">
                    <div class="meta-info">
                      <div class="row">
                        <div class="col-lg-9 d-flex">
                          <div class="avatar avatar-online me-2">
                            <img :src="getImageUrl('logo.png', '')" alt="Baketrans" class="avatar-img rounded-circle">
                          </div>
                          <div class="media-body text-start">
                            <div>
                              <div class="user-name"><strong>Badan Kebijakan Transportasi</strong></div>
                              <div class="user-last-chat">{{ formattedDate(content.published) }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 text-end">
                          <div class="last-chat-time block"><i class="fas fa-user"></i> {{ content.writeby }}</div>
                          <div class="last-chat-time block"><i class="fas fa-eye"></i> {{ content.views }}x dilihat
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Blog Image -->
                  <div class="blog-post-image-container">
                    <a @click.prevent="showImage(content.image)">
                      <img class="img-strecth blog-post-image" v-lazy="content.image"  alt="baketrans" />
                    </a>

                  </div>
                  <!-- Blog Content -->
                  <div class="blogdetail-content p-2">
                    <h5 class="mb-4 mt-4">Berita</h5>
                    <h2>{{ content.title }}</h2>
                    <div class="container">
                      <div class="news-content" v-html="content.html"></div>
                    </div>
                    <div class="card p-2 m-2" v-for="(row, index) in content.download" :key="item">
                      <div class="text-md-end">
                        <span class="me-4">{{row.title}}</span>
                        <a href="categories.html" class="btn  btn-view"> <i class="fas fa-download"></i> Download</a>
                      </div>
                    </div>
                  </div>
                  <div class="related-posts-section">
                    <h5>Postingan Terkait</h5>
                    <div class="row">
                      <div v-for="(post, index) in content.related" :key="index" class="col-lg-4">
                        <router-link  :to="`/berita/${post.slug}`"
                          class="related-post-card">
                          <div class="related-post-image-container">
                            <img v-lazy="post.thumbnail" class="related-post-image img-fluid"
                              alt="related-post-img">
                          </div>
                          <div class="related-post-info">
                            <h4>{{ post.title }}</h4>
                          </div>
                          <div class="d-flex related-post-meta">
                            <div class="me-2">
                              <img :src="getImageUrl('logo.png', '')" alt="Baketrans" class="logo-img rounded-circle">
                            </div>
                            <div class="media-body text-start">
                              <div class="">
                                <div class="user-name"><strong>Badan Kebijakan Transportasi</strong></div>
                                <div class="user-last-chat">{{ formattedDate(post.published) }}</div>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 theiaStickySidebar">
              <div class="stickysidebar">
                <div class="card">
                  <div class="card-body pb-1">
                    <h6>
                      <i class="bi bi-search"></i> Cari Berita
                    </h6>
                    <div class="filter-content looking-input form-group">
                      <input type="text" class="form-control" placeholder="keyword...">
                    </div>
                  </div>
                </div>
                <div class="rightsidebar blogsidebar">
                  <div class="card">
                    <h4>
                      <i class="bi bi-briefcase"></i> Kategori
                    </h4>
                    <ul class="blogcategories-list p-0">
                      <li v-for="(row, index) in baseDataKategori" :key="item">
                        <a href="javascript:;" @click.prevent="changeCategories(row, index)" v-if="!row.uioptions.loading">
                          {{ row.title }}
                          <span>{{ row.total }} </span>
                        </a>
                        <a href="javascript:;" v-if="row.uioptions.loading">
                          {{ row.title }}
                          <i class="spinner-border spinner-border-sm align-middle me-2"></i>
                          <span>{{ row.total }} </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="rightsidebar blogsidebar mt-4">
                  <div class="card tags-widget">
                    <h4>
                      <i class="feather-tag"></i> Tagar
                    </h4>
                    <ul class="tags">
                      <li>#Kebijakan</li>
                      <li>#Infrastruktur</li>
                      <li>#Keselamatan</li>
                      <li>#Transportasi</li>
                      <li>#Darat</li>
                      <li>#Laut</li>
                      <li>#Udara</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import AppBreadCrumb from '@/views/layouts/bread-crumb.vue'
import { mapState, mapActions, useStore } from 'vuex'
import Swal from 'sweetalert2'

export default {
  components: {
    AppBreadCrumb
  },
  data() {
    return {
      info: {
        id: null,
        meta: null
      },
      content: {
        title: "",
        description: "",
        html: "",
        download: [],
        image: "",
        author: "",
        writeby: "",
        source: "",
        published: "",
        views: 0,
        tag: [],
        related: []
      },
      relatedPosts: [],
      placeholderImage: '/assets/images/icons/logo-trans.png', 
      data: {
        name: "Direktori Baketrans",
        title: "Direktori Seputar Baketrans",
        description: "",
        link: [
          {
            title: "Direktori",
            path: "/directory",
            description: ""
          },
          {
            title: "Informasi Direktori",
            path: "/directory",
            description: ""
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions('directoryPage', ['getMateriContent']),
    ...mapActions('kategoriPage', ['getNewsCategory']),
    getImageUrl(imageName, folder) {
      try {
        //return require(`@/assets/img/${folder}/${imageName}`);
        return `/assets/images/${imageName}`;
      } catch (e) {
        return this.placeholderImage;
      }
    },
    formattedDate(tdate) {
      if (tdate) {
        const changedDate = tdate.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
        var date = new Date(changedDate);

        return new Intl.DateTimeFormat('id-ID', {
          timeZone: 'Asia/Jakarta',
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          weekday: 'long'

        }).format(date);
      }
    },
    async changeCategories(value, index) {
      this.$router.push('/berita/kategori/' + value.slug);
    },
    showImage(url) {
      if (url == null) {
        url = this.options.placeholderImage;
      }
      this.$startLoading();
      Swal.fire({
        html: `
        <div v-lazy-container="{ selector: 'img', error: '/assets/images/icons/image-load-failed.png', loading: '/assets/images/icons/image-load.gif' }">
          <img class="img-fluid" src="${url}">
        </div>
        `,
        showConfirmButton: false,
        showCloseButton: true,
        //timer: 2500,
        width: 854,
        heightAuto: false,

        customClass: {
          confirmButton: "btn btn-light-success btn-sm",
          cancelButton: "btn btn-light-danger btn-sm"
        },
        buttonsStyling: false,
        didOpen: () => {
          this.$completeLoading();
        }
      });
    },
  },
  watch: {
    async $route(to, from) {
     
      this.content.image=this.placeholderImage;
      if (to.params.id !== from.params.id) {
        try {
          this.id = await this.$route.params.id;
          await this.getMateriContent([this.id]);
          await this.getNewsCategory(["berita"])
        }
        catch (e) {
          return this.placeholderImage;
        }
        finally {
          this.content.download = this.baseData.files;
          this.content.title = this.baseData.news.title;
          this.content.description = this.baseData.news.description;
          this.content.html = this.baseData.news.content;
          this.content.image = this.baseData.news.image;
          this.content.author = this.baseData.news.author;
          this.content.writeby = this.baseData.news.writeby;
          this.content.source = this.baseData.news.source;
          this.content.published = this.baseData.news.published;
          this.content.views = this.baseData.news.views;
          this.content.tag = this.baseData.news.tag;
          this.content.related = this.baseData.related;
        }
      }
    }
  },
  async mounted() {
    try {
      this.info.id = await this.$route.params.id;
      this.info.meta = await this.$route.params.meta;
     
      await this.getMateriContent([this.info.id,this.info.meta]);
      await this.getNewsCategory(["berita"])
    }
    catch (e) {
      return this.placeholderImage;
    }
    finally {
      this.content.download = this.baseData.files;
      this.content.title = this.baseData.news.title;
      this.content.description = this.baseData.news.description;
      this.content.html = this.baseData.news.content;
      this.content.image = this.baseData.news.image;
      this.content.author = this.baseData.news.author;
      this.content.writeby = this.baseData.news.writeby;
      this.content.source = this.baseData.news.source;
      this.content.published = this.baseData.news.published;
      this.content.views = this.baseData.news.views;
      this.content.tag = this.baseData.news.tag;
      this.content.related = this.baseData.related;
    }
  },
  computed: {
    ...mapState('directoryPage', {
      baseData: (state) => state.materi_content
    }),
    ...mapState('kategoriPage', {
      baseDataKategori: (state) => state.kategori
    }),

  }
}

</script>

<style scoped>
.berita-single-section {
  padding-top: 30px;
  padding-bottom: 20px;
  background: #f9fafc;
  /* Default padding for larger screens */
}

@media (max-width: 991px) {
  .berita-single-section {
    background: #f9fafc;
    padding-top: 10px;
    /* Adjust this value as needed */
  }
}

.blog-title h1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
}

.blog-meta {
  margin-bottom: 0.5rem;
}

.meta-info span {
  font-size: 0.9rem;
  color: #777;
  margin-right: 1rem;
}

.meta-info i {
  margin-right: 0.3rem;
}

.blog-post-image-container {
  display: block;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.blog-post-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  border: 4px solid #eceff1;
}

@media (max-width: 991px) {
  .blog-post-image {
    width: 100%;
    /* Adjust the width for smaller screens */
  }
}

.blog-content {
  line-height: 1.7;
  color: #444;
  font-size: 1rem;
}

.blog-content .introduction,
.blog-content .conclusion {
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.blog-content .content-subheading {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #333;
}

.blog-content p {
  margin-bottom: 1rem;
}

.highlighted-quote {
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  border-left: 4px solid #333;
  background-color: #f9f9f9;
  color: #555;
}

.share-postsection {
  margin-top: 2rem;
}

.related-posts-section {
  margin-top: 1rem;
}

.related-posts-section h5{
  font-size: 1rem;
}

.related-post-card {
  display: block;
  text-decoration: none;
  color: inherit;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
  margin-bottom: 15px;
}

.related-post-card:hover {
  transform: translateY(-5px);
  /* Add a subtle hover effect */
}

.related-post-image-container {
  width: 100%;
  height: 170px;
  /* Fixed height for consistency */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.related-post-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image covers the container while maintaining aspect ratio */
  border-radius: 10px;
  /* Adjust the radius for rounded corners */
  border: 4px solid #eceff1;
}

.related-post-info {
  margin-top: 5px;
  padding: 5px;
  text-align: left;
  /* Ensures consistent text alignment */
}

.related-post-info h4 {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Ensures long titles don't overflow */
  -webkit-line-clamp: 3 !important;
  display: -webkit-box;
  line-height: 1.3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.related-post-meta {
  font-size: 0.7rem;
}

.ellipsis3 {
  -webkit-line-clamp: 3 !important;
  display: -webkit-box;
  line-height: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.ellipsis4 {
  -webkit-line-clamp: 4 !important;
  display: -webkit-box;
  line-height: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: justify;
}

.review-details .rating div {
  font-size: 12px;
  margin-left: 5px;
}

.rating i {
  font-size: 10px;
}

.icon32x32 {
  height: 24px;
  width: 24px;
}

.rightsidebar .card ul.blogcategories-list li {
  list-style: none;
  margin-left: 0;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rightsidebar .card ul.blogcategories-list li span {
  float: right;
  background-color: #001165;
  color: #fff;
  border-radius: 3px;
  padding: 2px;
  min-width: 35px;
  max-width: 50px;
  height: 16px;
  text-align: center;
  vertical-align: middle;
  line-height: 13px;
  font-size: 11px;
  border-left: 2px solid #F9C11E;
  border-right: 2px solid #F9C11E;
}

.rightsidebar .card ul.blogcategories-list li:before {
  content: "\f35a";
  font-family: FontAwesome;
  display: inline-block;
  margin-right: 10px;
  margin-left: 0;
  color: #001165;
}


.size-title {
  font-size: 1.475rem !important;
  text-align: center;
  padding-left: 100px !important;
  padding-right: 100px !important;
  color: #111827 !important;
  font-weight: 600 !important;
}

.logo-img {
  width: 35px;
  height: 35px;
}

.blogdetail-content {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.blogdetail-content h2 {
  font-size: 1.2rem;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
}

.blogdetail-content h5 {
  font-size: 1rem;

  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 991px) {
  .size-title {
    font-size: 1.175rem !important;
    text-align: center;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .card {
    padding: 10px 10px;
  }

  .card-body {
    padding: 10px 10px;
  }
}

.news-content {
  text-align: justify;
  font-size: 14px;
}

@media (max-width: 600px) {
  .blog-post-image {
    height: 250px;
  }

  .related-post-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the container while maintaining aspect ratio */
    border-radius: 10px;
    /* Adjust the radius for rounded corners */
    border: 4px solid #eceff1;
  }
}
</style>